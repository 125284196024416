<hx-loader *ngIf="isLoading.order" [isLoading]="isLoading.order"></hx-loader>
<ng-container *ngIf="order">
  <div class="modal-header" [ngClass]="{'modal-header--success': order.paid}">
    <h3 class="modal-title">
      {{ 'cashbox-operation.order' | transloco }} <strong>{{ order.uniqueNumber }}</strong>
      <span *ngIf="order.paid">
        {{ 'cashbox-operation.paid' | transloco }} {{order | paymentInfo: childOrders}}{{ currencySymbol }}
      </span>
      <ng-container *ngIf="!order.paid">
        <span *ngIf="!order.debt" style="font-size: 18px;">
        {{ 'cashbox-operation.totalPayment' | transloco }} <span style="font-weight: bold">{{ order.totalToPay }} {{ currencySymbol }}</span>
        </span>
        <span *ngIf="order.debt" style="font-size: 18px;">
          {{ 'cashbox-operation.borrowed' | transloco }} <span style="font-weight: bold">{{ order.totalToPay }} {{ currencySymbol }}</span>
        </span>
      </ng-container>
    </h3>
    <button *ngIf="!isSoldPaidOperation" class="close" type="button" (click)="dismissModal()">
      <span>×</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="isLoading.sold || isLoading.paid" class="m-page-loader m-page-loader--non-block m-page-loader--relative">
      <div class="m-loader m-loader--brand"></div>
    </div>
    <ng-container *ngIf="!isExternalPay && !isLoading.sold && !isLoading.paid">
      <div class="cb-soldmodal">
        <ng-container *ngIf="isMixedPayment && !order.debt">
          <div class="cb-soldmodal__line">
            <div class="cb-soldmodal__data">
              {{ 'cashbox-operation.cash' | transloco }}
            </div>
            <div class="cb-soldmodal__value">
              <div class="input-group" style="width: 200px;">
                <input type="number" class="form-control" [(ngModel)]="order.cash" [placeholder]="'cashbox-operation.placeholderSum' | transloco" (ngModelChange)="onCashChanged()">
                <span class="input-group-addon"> {{ currencySymbol }}</span>
              </div>
            </div>
          </div>

          <div class="cb-soldmodal__line">
            <div class="cb-soldmodal__data">
              POS
            </div>
            <div class="cb-soldmodal__value">
              <div class="input-group" style="width: 200px;">
                <input type="number" class="form-control" [(ngModel)]="order.pos" [placeholder]="'cashbox-operation.placeholderSum' | transloco" (ngModelChange)="onPosChanged()">
                <span class="input-group-addon"> {{ currencySymbol }}</span>
              </div>
            </div>
          </div>

          <div class="cb-soldmodal__line">
            <div class="cb-soldmodal__data">
              Kaspi POS
            </div>
            <div class="cb-soldmodal__value">
              <div class="input-group" style="width: 200px;">
                <input type="number" class="form-control" [(ngModel)]="order.kaspiPos" [placeholder]="'cashbox-operation.placeholderSum' | transloco" (ngModelChange)="onKaspiPosChanged()">
                <span class="input-group-addon"> {{ currencySymbol }}</span>
              </div>
            </div>
          </div>

          <h3 *ngIf="paymentRemainder !== 0" class="text-right">{{paymentRemainder}}</h3>
        </ng-container>

        <ng-container *ngIf="!order.paid">
          <div class="payment-type" *ngIf="!isMixedPayment && order.total > 0 && !order.debt">
            <div *ngIf="paymentTypes.includes(paymentType.CASH)" class="form-group m-form__group">
              <label>
                <input class="ht-radio" type="radio" name="paymentType" [value]="paymentType.CASH" [(ngModel)]="selectedPaymentType" (click)="radioChange(paymentType.CASH)"> {{ 'cashbox-operation.cash' | transloco }}
                <span></span>
              </label>
            </div>

            <div *ngIf="paymentTypes.includes(paymentType.POS)" class="form-group m-form__group">
              <label>
                <input class="ht-radio" type="radio" name="paymentType" [value]="paymentType.POS" [(ngModel)]="selectedPaymentType" (click)="radioChange(paymentType.POS)"> {{ 'cashbox-operation.map' | transloco }}
                <span></span>
              </label>
            </div>

            <div *ngIf="paymentTypes.includes(paymentType.KASPI_POS)" class="form-group m-form__group">
              <label>
                <input class="ht-radio" type="radio" name="paymentType" [value]="paymentType.KASPI_POS" [(ngModel)]="selectedPaymentType" (click)="radioChange(paymentType.KASPI_POS)"> {{ 'cashbox-operation.bank' | transloco }}
                <span></span>
              </label>
            </div>

            <div *ngIf="paymentTypes.includes(paymentType.CHECKING_ACCOUNT)" class="form-group m-form__group">
              <label>
                <input class="ht-radio" type="radio" name="paymentType" [value]="paymentType.CHECKING_ACCOUNT" [(ngModel)]="selectedPaymentType" (click)="radioChange(paymentType.CHECKING_ACCOUNT)"> {{ 'cashbox-operation.account' | transloco }}
                <span></span>
              </label>
            </div>

            <div *ngIf="paymentTypes.includes(paymentType.SQUAREUP)" class="form-group m-form__group">
              <label>
                <input class="ht-radio" type="radio" name="paymentType" [value]="paymentType.SQUAREUP" [(ngModel)]="selectedPaymentType" (click)="radioChange(paymentType.SQUAREUP)"> {{ 'cashbox-operation.squareup' | transloco }}
                <span></span>
              </label>
            </div>
          </div>
        </ng-container>

        <div class="cb-soldmodal__line">
          <hx-promo-code *ngIf="!isSoldPaidOperation && !order.paid" [discounts]="discounts" [orderId]="orderId"></hx-promo-code>

          <div class="print-check-container">
            <label for="print-check">
              {{ 'cashbox-operation.receipt' | transloco }}
            </label>

            <span class="m-switch m-switch--icon m-switch--outline m-switch--success">
              <label>
                <input type="checkbox" id="print-check" [(ngModel)]="isPrintCheck">
                <span></span>
              </label>
            </span>
          </div>

          <div class="mixed-payment-container" *ngIf="!paymentTypes.includes(paymentType.SQUAREUP)">
            <label for="toggle-mixed-payment">
              {{ 'cashbox-operation.toggleText' | transloco }}
            </label>

            <span class="m-switch m-switch--icon m-switch--outline m-switch--success">
              <label>
                <input [disabled]="order.paid || !([paymentType.CASH, paymentType.POS, paymentType.KASPI_POS].includes(selectedPaymentType) && order.total > 0 && !order.debt)"
                       type="checkbox" id="toggle-mixed-payment" [(ngModel)]="isMixedPayment" (ngModelChange)="onMixedPaymentChanged()">
                <span></span>
              </label>
            </span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!isSoldPaidOperation">
        <table class="table table-light">
          <thead>
          <tr>
            <th>{{ 'cashbox-operation.productName' | transloco }}</th>
            <th style="width: 90px;">{{ 'cashbox-operation.weight' | transloco }}</th>
            <th style="width: 30px;">{{ 'cashbox-operation.before' | transloco }}</th>
            <th style="width: 150px;" class="text-center">{{ 'cashbox-operation.after' | transloco }}</th>
            <th style="width: 90px;" class="text-right">{{ 'cashbox-operation.sum' | transloco }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let i = index; let cartProduct of cartProducts; trackBy: trackByFn">
            <td>
              {{ cartProduct.productInfo.title | uiLabel }} <span *ngIf="cartProduct.price.amount !== 1">{{ cartProduct.price.amount }}</span>

              <ng-container *ngIf="cartProduct.discounts">
                <button *ngFor="let discount of cartProduct.discounts; let i = index" (click)="toggleDiscount(discount)"
                        [ngbTooltip]="(discount.enabled ? 'cashbox-operation.disableDiscount' : 'cashbox-operation.enableDiscount') | transloco"
                        class="btn btn-sm product-discount" [ngClass]="{'m--text-line-through': !discount.enabled}">
                  {{ discount.promoTitle | uiLabel }}
                  <span class="m-widget1__number m--font-success">
                    <!-- TODO @deedarb #ins other result types ? -->
                    <span *ngIf="discount.resultType === 'PERCENT_DISCOUNT'">{{ discount.promoValue }} % ({{ discount.value | uiCurrency: currency }})</span>
                    <span *ngIf="discount.resultType === 'MONEY_DISCOUNT'">{{ discount.value | uiCurrency: currency }}</span>
                  </span>
                  ({{discount.productAmount}})
                </button>
              </ng-container>
            </td>
            <td>
              {{cartProduct.weight | weight}}
            </td>
            <td>
              {{cartProduct.beforeAmount}}
            </td>
            <td>
              <div class="input-group m-form__group" style="width: 100px;">
                <button (click)="subtractProductAmount(cartProduct)" type="button" [disabled]="isLoading.discounts || cartProduct.afterAmount === 0" class="btn btn-default input-group-addon">
                  <i class="la la-minus"></i>
                </button>
                <label class="text-center min-width-60">{{cartProduct.afterAmount}}</label>
                <button (click)="addProduct(cartProduct.price)" [disabled]="isLoading.discounts" type="button" class="btn btn-default input-group-addon">
                  <i class="la la-plus"></i>
                </button>
              </div>
            </td>
            <td class="text-right">
              {{cartProduct.value}}
            </td>
          </tr>
          <tr>
            <td colspan="3">
              {{ 'cashbox-operation.total' | transloco }}:
            </td>
            <td class="text-center">
              {{ totalItem }}
            </td>
            <td class="text-right">
              <span class="m-widget1__number" [ngClass]="{'m--font-accent': order.subTotal !== order.total}">
                <span *ngIf="order.subTotal !== order.total" class="m--text-line-through">{{ order.subTotal | uiCurrency: currency }}</span>
                {{ order.total | uiCurrency: currency }}
              </span>
            </td>
          </tr>
          </tbody>
        </table>

        <div *ngIf="cartProducts.length">
          <h4 class="m--margin-bottom-15">{{ 'cashbox-operation.nomenclature' | transloco }}</h4>
          <app-product-kind-list [currencySymbol]="currencySymbol" [storeId]="order.storeId" (productAdded)="onProductAdded($event)"></app-product-kind-list>
        </div>

        <div *ngIf="!order.paid">
          <button type="button" class="btn btn-primary" (click)="toggleProductList()">
            {{ (isProductList ? 'cashbox-operation.hide' : 'cashbox-operation.add') | transloco}} {{ ('buttons.productSmall' | transloco) }}
          </button>

          <hx-product-list
            *ngIf="isProductList && orderStore && orderDate"
            [date]="orderDate"
            [store]="orderStore"
            [currency]="currency"
            (productAdded)="onProductAdded($event)"></hx-product-list>
        </div>
      </ng-container>
    </ng-container>

    <hc-pay *ngIf="isExternalPay && orderModel && payType"
            [order]="orderModel"
            [paymentType]="payType" (orderPaid)="onOrderPaid()"></hc-pay>

    <div *ngIf="showOrderPaidMessage" class="alert alert-success">
      {{ 'cashbox-operation.orderStatus' | transloco}}
    </div>

    <div class="btn-group-sm referrers">
      <button *ngFor="let item of referrers" (click)="setReferrer(item)"
              class="btn m-btn--pill btn-sm" [ngClass]="{'btn-outline-info': item.code !== referrerEntry?.code, 'btn-info': item.code === referrerEntry?.code}">
        {{ item.title | uiLabel }}
      </button>
      <button *ngIf="otherReferrers.length > 0" (click)="toggleMoreReferrers()" [ngbTooltip]="'cashbox-operation.moreOptions' | transloco" class="btn m-btn--pill btn-sm">
        <i class="la" [ngClass]="{'la-arrow-up': otherReferrersVisible, 'la-arrow-down': !otherReferrersVisible}"></i>
      </button>
    </div>

    <div class="m--margin-top-15" *ngIf="otherReferrersVisible">
      <button *ngFor="let item of otherReferrers" (click)="setReferrer(item)"
              class="btn m-btn--pill btn-sm" [ngClass]="{'btn-outline-info': item.code !== referrerEntry?.code, 'btn-info': item.code === referrerEntry?.code}">
        {{ item.title | uiLabel }}
      </button>
    </div>

    <div *ngIf="order.clientId" class="m--margin-top-15">
      <span *ngIf="isLoading.coins" class="la la-spinner has--spin"></span>
      <ng-container *ngIf="!isLoading.coins">
        <span>{{ 'cashbox-operation.coins.willApply' | transloco}}: <strong>{{coinEarnAmount}}</strong></span>
        <span style="margin-left: 1rem;">{{ 'cashbox-operation.coins.clientActiveAmount' | transloco}}: <strong>{{totalCoin}}</strong></span>

        <ng-container *ngIf="coinAmount > 0">
          <label style="margin-left: 1rem;" for="apply-coins-checkbox">
            {{ 'cashbox-operation.coins.apply' | transloco }}
          </label>
          <span id="apply-coins-checkbox" class="m-switch m-switch--icon m-switch--outline m-switch--success">
            <label>
              <input type="checkbox" [disabled]="order.paid" [(ngModel)]="applyCoins" (ngModelChange)="onApplyCoinsChanged()">
              <span></span>
            </label>
          </span>

          <span *ngIf="applyCoins" style="margin-left: 1rem;">
            <span *ngIf="coinAmount > order.total" class="coin-amount">{{coinAmount - order.total}}</span>
            <span *ngIf="coinAmount <= order.total" class="coin-amount">{{coinAmount}}</span> + {{order.total - coinAmount}}
          </span>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="modal-footer">
    <div *ngIf="!order.paid && order.total > 0" class="cp__pay-btn-group">
      <ng-container *ngIf="!isExternalPay">
        <button *ngIf="paymentTypes.includes(paymentType.KASPI)" (click)="setPaymentType(paymentType.KASPI)"
                [disabled]="hasUnsavedProducts" type="button" class="btn btn-secondary">
          <span>{{ 'cashbox-operation.bankKz' | transloco }}</span>
        </button>
      </ng-container>

      <button *ngIf="payType" (click)="resetPaymentType()" type="button" class="btn btn-secondary mr-auto">
        <span>{{ 'buttons.changePayment' | transloco }}</span>
      </button>
    </div>

    <div class="cp__actions">
      <label *ngIf="isSoldOperation && orderModel?.clientType === 'LEGAL'" class="m-checkbox m-checkbox--text" style="margin: auto; padding-right: 0.5rem;">
        <input type="checkbox" [(ngModel)]="isReceivedDocs">
        {{ 'cashbox-operation.gotSigned' | transloco }}
        <span></span>
      </label>
      <button *ngIf="!isExternalPay && hasUnsavedProducts" (click)="saveOrderWithProducts()"
              [disabled]="isLoading.discounts"
              type="button" class="btn btn-outline-success" style="margin-right: 1rem;">
        <i class="la la-check-circle"></i> {{ 'buttons.saveProduct' | transloco }}
      </button>
      <button [disabled]="isLoading.discounts" type="button" (click)="recalculateTotal()" class="btn btn-outline-primary">
        <span *ngIf="isLoading.discounts" class="la la-spinner has--spin"></span>
        {{ 'buttons.recalculate' | transloco }}
      </button>
      <div class="m-widget1__number" [ngClass]="{'m--font-accent': order.subTotal !== order.total}">
        <span *ngIf="order.subTotal !== order.total" class="m--text-line-through">{{ order.subTotal | uiCurrency: currency }}</span>
        {{ order.total | uiCurrency: currency }} <span *ngIf="order?.tax ?? 0 > 0"> + {{ order.tax | uiCurrency: currency }} {{ 'cashbox-operation.tax' | transloco }}</span>
      </div>

      <span *ngIf="isSoldOperation" [ngbTooltip]="(hasUnsavedProducts ? 'cashbox-operation.saveProducts'  : '') | transloco">
        <button *ngIf="!isExternalPay" (click)="sold()" type="button"
                [disabled]="isLoading.sold || isLoading.discounts || hasUnsavedProducts || (!isReceivedDocs && orderModel?.clientType === 'LEGAL')" class="btn btn-success m--margin-right-10">
          <span *ngIf="isLoading.sold" class="la la-spinner has--spin"></span>
          {{ 'buttons.sell' | transloco }}
        </button>
      </span>

      <span *ngIf="isPaidOperation" [ngbTooltip]="hasUnsavedProducts ? ('cashbox-operation.saveProducts' | transloco) : ''">
        <button *ngIf="!isExternalPay" (click)="paid()" type="button"
                [disabled]="isLoading.paid || isLoading.discounts || hasUnsavedProducts || order.paid" class="btn btn-success m--margin-right-10">
          <span *ngIf="isLoading.paid" class="la la-spinner has--spin"></span>
          {{ 'buttons.savePayment' | transloco }}
        </button>
      </span>

      <ng-container *ngIf="isSoldPaidOperation">
        <button *ngIf="!isExternalPay"
                (click)="soldPaid()" type="button"
                [disabled]="isLoading.sold || isLoading.discounts" class="btn btn-success m--margin-right-10">
          <span *ngIf="isLoading.sold" class="la la-spinner has--spin"></span>
          {{ 'buttons.sell' | transloco}}
        </button>

        <button (click)="showCancelOrderModal()" class="btn btn-outline-danger m-btn m-btn--icon m--margin-left-5" [title]="(order.paid ? 'cashbox-operation.orderReturn' : 'cashbox-operation.cancelOrder') | transloco">
          <span>
            <i class="la la-remove"></i>
            <span *ngIf="order.paid">{{ 'buttons.return' | transloco }}</span>
            <span *ngIf="!order.paid">{{ 'buttons.cancel' | transloco }}</span>
          </span>
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>
