import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../vn-api';
import { getCurrencySymbol } from '../interface';

/**
 * Pipe Currency - show currency symbol
 */
@Pipe({
  name: 'uiCurrency'
})
export class HxCurrencyPipe implements PipeTransform {

  transform(value: number | undefined | null, currency?: Currency): string {
    if (value === undefined || value === null) {
      return '';
    }
    if (currency === 'USD') {
      return `${getCurrencySymbol(currency)} ${value}`;
    }
    return `${value} ${getCurrencySymbol(currency)}`;
  }

}
