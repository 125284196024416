import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { HxClientSearchComponent } from './components/client/search/client-search.component';
import { HxUserSearchComponent } from './components/user-search/user-search.component';
import { HxCallAudioComponent } from './components/call-audio/call-audio.component';
import { HxProductInfoSelectComponent } from './components/product/info-select/product-info-select.component';
import { HxClientEditComponent } from './components/client/edit/client-edit.component';
import { HxClientEditModal } from './components/client/edit/client-edit-modal/client-edit.modal';
import { HxOrderReceiptComponent } from './components/order/receipt/order-receipt.component';
import { HxProductListComponent } from './components/product/list/product-list.component';
import { HxClientCheckerComponent } from './components/client/checker/client-checker.component';
import { HxLoaderComponent } from './components/loader/hx-loader.component';
import { HxRatingComponent } from './components/rating/rating.component';
import { HxConfirmModalComponent } from './components/modal/confirm/confirm-modal.component';
import { HxSmsInfoModalComponent } from './components/event/modal/sms-info-modal.component';
import { HxFileListComponent } from './components/file-list/file-list.component';
import { HxFileUploadComponent } from './components/file-upload/file-upload.component';
import { HxCheckboxComponent } from './components/checkbox/checkbox.component';
import { HxDateRangePickerComponent } from './components/datepicker/daterangepicker/daterangepicker.component';
import { HxPipeModule } from 'hx-services';
import { HxProductCategorySelectComponent } from './components/product/category-select/product-category-select.component';
import { HxCitySelectComponent } from './components/city-select/city-select.component';
import { HxBankSelectComponent } from './components/bank-select/bank-select.component';
import { HxBankAccountSelectComponent } from './components/bank-account-select/bank-account-select.component';
import { HxInvoiceSelectComponent } from './components/invoice-select/invoice-select.component';
import { HxDictionarySelectComponent } from './components/dictionary-select/dictionary-select.component';
import { HxCountrySelectComponent } from './components/country-select/country-select.component';
import { HxLanguageSelectComponent } from './components/language-select/language-select.component';
import { HxPaymentIconDirective } from './directives/payment-icon.directive';
import { HxGisAddressComponent } from './components/gis/gis-address.component';
import { HxManualAddressComponent } from './components/manual-address/manual-address.component';
import { HxEnumSelectComponent } from './components/enum-select/enum-select.component';
import { HxDateSelectComponent } from './components/datepicker/date-select/date-select.component';
import { HxDateRangeSelectComponent } from './components/datepicker/date-range-select/date-range-select.component';
import { HxStoreSelectComponent } from './components/store-select/store-select.component';
import { HxUserSelectComponent } from './components/user-select/user-select.component';
import { HxBrandSelectComponent } from './components/brand-select/brand-select.component';
import { HxClientPropertyEditModal } from './components/client/edit/client-property-edit-modal/client-property-edit.modal';
import { HxClientIndicatorComponent } from './components/client/indicator/client-indicator.component';
import { HxCompanyInfoComponent } from './components/company-info/company-info.component';
import { HxModalActorComponent } from './components/modal/modal-actor/modal-actor.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { HxCommentListComponent } from './components/comment/list/comment-list.component';
import { HxModalCommentAddComponent } from './components/comment/modal/comment-add.modal';
// @ts-ignore
import * as ru from './i18n/ru.json';

// @ts-ignore
import * as en from './i18n/en.json';

// @ts-ignore
import * as kk from './i18n/kk.json';

const components = [
  HxDateRangePickerComponent,
  HxClientIndicatorComponent,
  HxDateSelectComponent,
  HxDateRangeSelectComponent,
  HxClientSearchComponent,
  HxUserSearchComponent,
  HxUserSelectComponent,
  HxCallAudioComponent,
  HxProductInfoSelectComponent,
  HxClientEditComponent,
  HxClientEditModal,
  HxProductListComponent,
  HxOrderReceiptComponent,
  HxClientCheckerComponent,
  HxCheckboxComponent,
  HxLoaderComponent,
  HxRatingComponent,
  HxClientPropertyEditModal,
  HxConfirmModalComponent,
  HxSmsInfoModalComponent,
  HxFileListComponent,
  HxFileUploadComponent,
  HxPaymentIconDirective,
  HxManualAddressComponent,
  HxGisAddressComponent,
  HxEnumSelectComponent,
  HxProductCategorySelectComponent,
  HxStoreSelectComponent,
  HxBrandSelectComponent,
  HxCompanyInfoComponent,
  HxCitySelectComponent,
  HxBankSelectComponent,
  HxBankAccountSelectComponent,
  HxInvoiceSelectComponent,
  HxDictionarySelectComponent,
  HxCountrySelectComponent,
  HxLanguageSelectComponent,
  HxModalActorComponent,
  HxCommentListComponent,
  HxModalCommentAddComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    TranslocoModule,
    HxPipeModule,
    NgxMaskDirective,
    NgxMaskPipe,
    RouterModule,
  ],
  exports: components,
  providers: [
    provideEnvironmentNgxMask(),
  ],
})
export class HxComponentModule {
  constructor(private translocoService: TranslocoService) {
    this.translocoService.setTranslation(ru, 'ru', {merge: true});
    this.translocoService.setTranslation(en, 'en', {merge: true});
    this.translocoService.setTranslation(kk, 'kk', {merge: true});
  }
}
