import { Component, Input, OnInit } from '@angular/core';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HxAuthService, HxInvoiceService, HxStoreService, ProductInfoType, StoreBasicModel, StoreProductModel } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-balance-new.m-grid__item.m-grid__item--fluid',
  templateUrl: './balance-new.modal.html',
  styleUrls: ['./balance-new.modal.css']
})
export class BalanceNewModal implements OnInit {
  @Input() storeId!: number;
  invoiceFormKeys = {
    fromStoreId: 'fromStoreId',
    toStoreId: 'toStoreId',
    description: 'description',
    products: 'products'
  };
  productFormKeys = {
    productInfoId: 'productInfoId',
    amount: 'amount'
  };
  isLoading = {
    submit: false,
  };
  invoiceForm!: UntypedFormGroup;
  stores: StoreBasicModel[] = [];
  productInfoTypes: ProductInfoType[] = [ProductInfoType.PROPERTY, ProductInfoType.PRODUCT, ProductInfoType.MATERIAL_ASSETS, ProductInfoType.NON_MATERIAL_ASSETS];

  constructor(
    private toastr: ToastrService,
    private errorService: ErrorHandlerService,
    private modalInstance: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private invoiceService: HxInvoiceService,
    private storeService: HxStoreService,
    private auth: HxAuthService,
    private tr: TranslocoService
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.loadStores();
  }

  dismissModal() {
    this.modalInstance.dismiss();
  }

  onSubmitForm() {
    // eslint-disable-next-line guard-for-in
    for (const i in this.invoiceForm.controls) {
      this.invoiceForm.controls[i].markAsDirty();
      this.invoiceForm.controls[i].updateValueAndValidity();
    }
    if (this.invoiceForm.valid) {
      this.invoiceForm.disable();
      this.isLoading.submit = true;
      const obj = this.invoiceForm.getRawValue();
      this.invoiceService.createInvoice(obj).subscribe(() => {
        this.toastr.success(this.tr.translate('balance-new.access'));
        this.isLoading.submit = false;
        this.modalInstance.close();
      }, err => {
        this.isLoading.submit = false;
        this.errorService.handle(err);
      });
    }
  }

  addItem() {
    this.asFormArray(this.invoiceFormKeys.products).push(this.createItem());
  }

  removeItem(index: number) {
    this.asFormArray(this.invoiceFormKeys.products).removeAt(index);
  }

  asFormArray(path: string): UntypedFormArray {
    return this.invoiceForm.get(path) as UntypedFormArray;
  }

  onStoreProductSelected(item: StoreProductModel, index: number): void {
    this.asFormArray(this.invoiceFormKeys.products).at(index).patchValue({productInfoId: item.productInfo.id});
  }

  private createForm(): void {
    this.invoiceForm = this.fb.group({
      fromStoreId: [this.storeId, [Validators.required]],
      toStoreId: [null, [Validators.required]],
      description: [null],
      products: this.fb.array([], [Validators.required]),
    });
  }

  private createItem() {
    return this.fb.group({
      productInfoId: [null, [Validators.required]],
      amount: [null, [Validators.required, Validators.min(0)]],
    });
  }

  private loadStores(): void {
    const storeId = this.auth.user.store.id;
    this.storeService.getOwnerAllStores(storeId)
      .subscribe({
        next: stores => this.stores = stores.filter(r => r.id !== storeId && r.active),
        error: err => this.errorService.handle(err)
      });
  }
}
