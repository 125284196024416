import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ClientFullModel,
  Currency,
  debtAllowed,
  DebtPropertyModel,
  getCurrencySymbol,
  HxAuthService,
  HxOrderService,
  HxOrganizationService,
  HxProductService,
  HxProductSplitDefinitionService,
  HxRejectionService,
  KindType,
  NotifierEventBusAddress,
  NotifierService,
  OrderProductModel,
  OrderStatus,
  ProductShortModel,
  ProductSplitDefinitionFullModel,
  ReceiptModel,
  SalePriceModel,
  SaleProductModel,
  StoreBasicModel
} from 'hx-services';
import { LoaderService } from '@cashbox-app/service/loader.service';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { OrderCashbox } from '@cashbox-app/utils/ordercashbox';
import { CartItem } from '@cashbox-app/components/cart-list/cart-list.component';
import { HxConfirmModalComponent, HxModalData } from 'hx-component';
import { ActivatedRoute, Router } from '@angular/router';
import { CashboxService } from '@cashbox-app/service/cashbox.service';
import { takeUntil } from 'rxjs/operators';
import { CashboxOperationModal } from '@cashbox-app/modal/cashbox-operation/cashbox-operation.modal';
import { FiscalizationService } from '@cashbox-app/service/fiscalization.service';
import { SplitProductModal } from '@cashbox-app/modal/split-product/split-product.modal';
import { TranslocoService } from '@ngneat/transloco';
import { formatISO } from 'date-fns';
import { HC_PRINT_NUMBER } from '@cashbox-app/utils/util';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-cashbox.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './order-cashbox.component.html',
  styleUrls: ['./order-cashbox.component.css'],
  providers: [LoaderService],
  encapsulation: ViewEncapsulation.None,
})
export class OrderCashboxComponent implements OnInit, OnDestroy {
  cbOrder = new OrderCashbox();
  cartProducts: ProductShortModel[] = [];
  categories = [];
  cart: CartItem[] = [];
  requestList: number[] = [];
  isClientShow = false;
  currency?: Currency;
  currencySymbol?: string;
  orderId?: number;
  timesToPrint: number = Number(localStorage.getItem(HC_PRINT_NUMBER) ?? '1');
  isLoading = {
    sell: false,
    order: true,
    generateCart: false,
    cancelCart: false,
    prolongCartExpiration: false,
  };
  countDown?: number;
  status = 'NONE';
  date = formatISO(new Date(), {representation: 'date'});
  cityId!: number;
  debtAllow = false;
  store!: StoreBasicModel;
  productActiveCart?: ProductShortModel;

  private interval?: NodeJS.Timeout;
  private defaultProducts = new Map<number, SaleProductModel>();
  private discountSub?: Subscription;
  private modalInstance?: NgbModalRef;
  private $destroyed = new ReplaySubject<void>(1);
  private readonly CANCEL_EVENT_NAME = 'hx-cart-cancel-event';
  private $rejection = new Subject<void>();

  constructor(
    private router: Router,
    private aRoute: ActivatedRoute,
    private modal: NgbModal,
    private toastr: ToastrService,
    public loaderService: LoaderService,
    private errorService: ErrorHandlerService,
    private orderService: HxOrderService,
    private cashboxService: CashboxService,
    private orgService: HxOrganizationService,
    private fiscalizationService: FiscalizationService,
    private rejectionService: HxRejectionService,
    private auth: HxAuthService,
    private productService: HxProductService,
    private productSplitDefinitionService: HxProductSplitDefinitionService,
    private notifierService: NotifierService,
    private tr: TranslocoService
  ) {
  }

  @HostListener('window:storage', ['$event'])
  onStorageEvent(ev: StorageEvent) {
    if (ev.newValue && ev.key === this.CANCEL_EVENT_NAME) {
      const cartEvent: { id: number } = JSON.parse(ev.newValue);
      if (cartEvent.id === this.orderId) {
        this.resetOrder();
      }
    }
  }

  private splitProductHandler: (err: any, message: { body: any, headers: any }) => void = (err, message) => {
    if (err) {
      console.error(err);
    } else {
      console.log('[order-cashbox] splitProductHandler message', message);
      if (message.body && message.body.state === 'START') {
        this.loadSplitActiveCart();
      } else if (message.body && message.body.state === 'END') {
        this.loadSplitActiveCart();
      }
    }
  };

  ngOnInit() {
    this.store = this.auth.user.store;
    this.cityId = this.auth.user.store.cityId;

    this.rejectionService.clearRejection();
    this.rejectionService.initRejection({storeId: this.store.id, cityId: this.cityId, date: this.date, orderId: this.orderId});
    this.aRoute.queryParams.pipe(takeUntil(this.$destroyed)).subscribe(params => {
      this.orderId = params['orderId'] ? Number(params['orderId']) : undefined;
      this.loadStore();
      this.loadSplitActiveCart();
      this.notifierService.eventBusOpenObs.pipe(takeUntil(this.$destroyed)).subscribe(eb => {
        if (eb) {
          this.notifierService.registerHandler(NotifierEventBusAddress.splitProduct(this.date, this.store.id), this.splitProductHandler);
        }
      });

      if (this.orderId) {
        this.loadOrderById(this.orderId);
      } else {
        this.isLoading.order = true;
        this.orderService.getActiveOrderCart().subscribe(response => {
          if (response.id) {
            this.router.navigate(['.'], {relativeTo: this.aRoute, queryParams: {orderId: response.id}});
          } else {
            this.isLoading.order = false;
            this.resetOrder();
          }
        });
      }
    });

    this.rejectionService.rejectionObs.pipe(takeUntil(this.$rejection)).subscribe(rejection => {
      if (rejection) {
        this.rejectionService.saveRejection(rejection).subscribe(result => {
          this.rejectionService.setRejection(result);
          this.toastr.success(this.tr.translate('order-cashbox.savedSuccessfully'));
        }, err => this.errorService.handle(err));
      }
    });

    this.orderService.recalcDiscountsObs.pipe(takeUntil(this.$destroyed))
      .subscribe(() => this.checkDiscount());
  }

  async loadOrderById(orderId: number) {
    this.isLoading.order = true;
    try {
      const response = await this.orderService.getOrderCartById(orderId);
      if (response && response.status === OrderStatus.CART) {
        this.cbOrder.id = response.id;
        this.cartProducts = response.products;
        this.checkCartExpiration();
      } else {
        this.resetOrder();
      }
    } finally {
      this.isLoading.order = false;
    }
  }

  ngOnDestroy() {
    this.notifierService.unregisterHandler(NotifierEventBusAddress.splitProduct(this.date, this.store.id), this.splitProductHandler);
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.discountSub) {
      this.discountSub.unsubscribe();
    }
    if (this.$destroyed) {
      this.$destroyed.next();
      this.$destroyed.complete();
    }
    this.$rejection.next(undefined);
    this.$rejection.complete();
  }

  updateClient(event?: ClientFullModel) {
    this.cbOrder.clientId = event?.client.id;
    this.checkDiscount();
  }

  canUnderSalary(): boolean {
    return this.cbOrder && !!this.cbOrder.clientId && this.cbOrder.otpVerified && this.debtAllow && this.cbOrder.discounts.some(item => debtAllowed(item));
  }

  updateDiscount() {
    this.checkDiscount();
    this.checkCartExpiration();
  }

  addToCart(optional: boolean, item: SaleProductModel, price: SalePriceModel, productId?: number) {
    let isNew = true;

    if (!optional) {
      const defaultCartProducts = this.cart.filter(cartProduct => this.defaultProducts.has(cartProduct.productInfoId));
      if (defaultCartProducts.length > 0) {
        defaultCartProducts.forEach(cartProduct => {
          cartProduct.quantity += 1;
        });
      } else {
        this.defaultProducts.forEach(defaultProduct => {
          defaultProduct.prices.forEach(defaultPrice => {
            this.addToCart(true, defaultProduct, defaultPrice);
          });
        });
      }
    }

    const obj: CartItem = {
      id: productId,
      productInfoId: item.id,
      title: item.title,
      amount: price.amount,
      counter: price.amount,
      value: price.value,
      total: price.value,
      priceId: price.id,
      priceAmount: price.amount,
      quantity: 1,
    };

    this.cart.forEach(element => {
      if (price.id === element.priceId) {
        element.id = productId;
        element.counter += obj.amount;
        element.total += obj.value;
        element.quantity += obj.quantity;
        isNew = false;
      }
    });

    if (isNew) {
      this.cart.push(obj);
    }

    this.checkDiscount();
  }

  updateCart(cart: CartItem[] = []) {
    this.cart = cart;
  }

  resetOrder(): void {
    if (this.discountSub && !this.discountSub.closed) {
      this.removeRequest();
      this.discountSub.unsubscribe();
    }
    this.cbOrder = new OrderCashbox();
    this.cart = [];
    this.loadStore();
    this.isClientShow = false;
    // this.isConfirmOtp = false;
    this.cartProducts = [];
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.countDown = 0;
    this.status = 'NONE';
    this.modal.dismissAll();
    this.router.navigate(['.'], {relativeTo: this.aRoute, queryParams: {}});
  }

  addClient() {
    this.orgService.getDebtAllowed(this.auth.user.store.organizationId)
      .subscribe(result => {
        this.debtAllow = false;
        if (result && result.enabled && result.value) {
          const debtProperty = result.value as DebtPropertyModel;
          this.debtAllow = debtProperty.allow;
        }
      });
    this.isClientShow = true;
  }

  async postponeOrder() {
    if (this.isLoading.sell) {
      return;
    }
    if (!this.cbOrder.clientId) {
      this.toastr.info(this.tr.translate('order-cashbox.numberClient'));
      return;
    }
    if (!this.cbOrder.id) {
      throw new Error('orderId.undefined');
    }
    const products: OrderProductModel[] = this.cart.map(item => ({
      priceId: item.priceId,
      amount: item.quantity,
    }));

    this.isLoading.sell = true;
    try {
      const {uniqueNumber} = await this.cashboxService.updateOrder(this.cbOrder.id, {
        paid: false,
        storeId: this.cbOrder.storeId,
        clientId: this.cbOrder.clientId,
        debt: this.cbOrder.debt,
        total: this.cbOrder.total,
        subTotal: this.cbOrder.subTotal,
        products: products,
        discounts: this.cbOrder.discounts,
      });
      this.toastr.success(this.tr.translate('order-cashbox.orderPending', {orderNumber: uniqueNumber}));
      this.resetOrder();
    } catch (err) {
      this.errorService.handle(err);
      this.resetOrder();
    } finally {
      this.isLoading.sell = false;
    }
  }

  async soldOrder() {
    if (this.isLoading.sell) {
      return;
    }
    if (!this.cbOrder.id) {
      throw new Error('orderId.undefined');
    }
    const products: OrderProductModel[] = this.cart.map(item => ({
      priceId: item.priceId,
      amount: item.quantity,
    }));

    this.isLoading.sell = true;
    try {
      const {orderId} = await this.cashboxService.updateOrder(this.cbOrder.id, {
        paid: false,
        storeId: this.cbOrder.storeId,
        clientId: this.cbOrder.clientId,
        debt: this.cbOrder.debt,
        total: this.cbOrder.total,
        subTotal: this.cbOrder.subTotal,
        products: products,
        discounts: this.cbOrder.discounts,
      });
      this.modalInstance = this.modal.open(CashboxOperationModal, {size: 'lg', backdrop: 'static'});
      this.modalInstance.componentInstance.orderId = orderId;
      this.modalInstance.componentInstance.operation = 'sold-paid';
      const result = await this.modalInstance.result;
      if (result && result.success) {
        this.toastr.success(this.tr.translate(`order.${result.operation}.success`));
        if (result.countryDomain !== 'us') {
          this.fiscalizationService.fiscalizeIfOffline(orderId).then(() => {
            if (result.print) {
              this.orderService.printCheck({orderId: orderId} as ReceiptModel);
            }
          });
        }
      }
    } catch (err: any) {
      if (err) {
        this.errorService.handle(err);
      }
    } finally {
      this.isLoading.sell = false;
      this.resetOrder();
    }
  }

  async onProductAdded(event: { item: SaleProductModel, price: SalePriceModel, product: { id?: number, isCart: boolean } }) {
    if (!this.orderId) {
      return;
    }
    const addToCartFn = (productId: number) => {
      this.addToCart(false, event.item, event.price, productId);
      this.checkCartExpiration();
    };
    if (event.product.isCart && event.product.id) {
      addToCartFn(event.product.id);
    } else {
      if (!this.cbOrder.id) {
        throw new Error('orderId.undefined');
      }
      try {
        const product = await this.cashboxService.addCartProduct(this.cbOrder.id, {priceId: event.price.id});
        addToCartFn(product.id);
      } catch (err: any) {
        console.error('err', err);
        this.errorService.handle(err);
      }
    }
  }

  onSplitSelected(events: ProductSplitDefinitionFullModel[]) {
    if (events && events.length > 0) {
      this.productService.reserveProduct({productInfoId: events[0].inProductInfo.id}).subscribe({
        next: () => {
          this.openSplitModal(events);
        },
        error: err => this.errorService.handle(err)
      });
    }
  }

  onOptionalProductAdded(event: { item: SaleProductModel, price: SalePriceModel }) {
    if (this.cbOrder.id) {
      this.addToCart(true, event.item, event.price);
    }
  }

  generateCart() {
    this.isLoading.generateCart = true;
    this.cashboxService.generateCart().then(response => {
      this.cartProducts = response.products;
      this.cbOrder.id = response.orderId;
      this.router.navigate(['.'], {relativeTo: this.aRoute, queryParams: {orderId: this.cbOrder.id}});

      this.checkCartExpiration();
      this.isLoading.generateCart = false;
    }).catch(err => {
      this.errorService.handle(err);
      this.isLoading.generateCart = false;
    });
  }

  cancelCart() {
    if (!this.cbOrder.id) {
      throw new Error('orderId.undefined');
    }
    const orderId = this.cbOrder.id;
    this.isLoading.cancelCart = true;
    const modalInstance = this.modal.open(HxConfirmModalComponent, {size: 'lg'});
    modalInstance.componentInstance.data = {
      message: 'cart.cancel.question',
      confirmMessage: 'cart.continue.yes',
      cancelMessage: 'cart.continue.no'
    } as HxModalData;
    modalInstance.result.then(() => {
      this.orderService.cancelCart(orderId).then(() => {
        this.isLoading.cancelCart = false;
        localStorage.setItem(this.CANCEL_EVENT_NAME, JSON.stringify({id: this.cbOrder.id}));
        localStorage.removeItem(this.CANCEL_EVENT_NAME);
        this.resetOrder();
      }, err => {
        console.error('err', err);
        this.isLoading.cancelCart = false;
      });
    }, (reason) => {
      this.isLoading.cancelCart = false;
      console.log('Dissmissed reason : ', reason);
    });
  }

  prolongCartExpiration() {
    if (!this.cbOrder.id) {
      throw new Error('orderId.undefined');
    }
    this.isLoading.prolongCartExpiration = true;
    this.orderService.prolongCartExpiration(this.cbOrder.id).subscribe(expiration => {
      this.status = expiration.status;
      if (expiration.status === 'ACTIVE') {
        this.startTimeout(expiration.seconds);
      }
      this.isLoading.prolongCartExpiration = false;
    }, () => this.isLoading.prolongCartExpiration = false);
  }


  onOtpConfirmed() {
    this.cbOrder.otpVerified = true;
  }

  activeSplitProduct() {
    if (!this.productActiveCart) {
      throw new Error('productActiveCart.undefined');
    }
    this.productSplitDefinitionService.getProductSplitDefinitions({
      limit: 100,
      inProductInfoId: this.productActiveCart.productInfo.id
    }).subscribe({
      next: result => {
        this.openSplitModal(result.list);
      }
    });
  }

  private checkCartExpiration() {
    if (!this.cbOrder.id) {
      throw new Error('orderId.undefined');
    }
    this.orderService.getCartExpiration(this.cbOrder.id).subscribe(expiration => {
      this.status = expiration.status;
      if (expiration.status === 'ACTIVE') {
        this.startTimeout(expiration.seconds);
      }
    });
  }

  private startTimeout(seconds: number) {
    this.countDown = seconds;

    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      if (this.countDown && this.countDown > 0) {
        this.countDown -= 1;
      } else {
        clearInterval(this.interval);
        this.resetOrder();
      }
      if (this.countDown === 60) {
        const modalInstance = this.modal.open(HxConfirmModalComponent, {size: 'lg'});
        modalInstance.componentInstance.data = {
          message: 'cart.continue.question',
          confirmMessage: 'cart.continue.yes',
          cancelMessage: 'cart.continue.no'
        } as HxModalData;
        modalInstance.result.then(() => {
          this.prolongCartExpiration();
        }, (reason) => {
          console.log('Dissmissed reason : ', reason);
        });
      }
    }, 1000);
  }

  private loadStore() {
    const user = {...this.auth.user};
    this.cbOrder.storeId = user.store.id;
    this.cbOrder.address = user.store.address;
    this.cbOrder.cityId = user.store.cityId;
    this.currency = user.store.currency;
    this.currencySymbol = getCurrencySymbol(this.currency);

    if (this.defaultProducts.size === 0) {
      this.cashboxService.getSaleProducts({storeId: this.cbOrder.storeId, kinds: [KindType.DEFAULT]}).then(result => {
        result.forEach(saleProduct => this.defaultProducts.set(saleProduct.id, saleProduct));
      });
    }
  }

  private removeRequest() {
    if (this.requestList.length) {
      this.requestList.splice(-1, 1);
    }

    this.loaderService.isLoading.next(this.requestList.length > 0);
  }

  private checkDiscount(): void {
    this.requestList.push(1);
    this.loaderService.isLoading.next(true);

    if (this.discountSub && !this.discountSub.closed) {
      this.removeRequest();
      this.discountSub.unsubscribe();
    }

    const request = this.cbOrder.getDiscountBody();
    request.products = this.cart.map(item => ({
      priceId: item.priceId,
      amount: item.quantity,
    }));

    this.discountSub = this.orderService.checkDiscount(request).subscribe(result => {
      this.cbOrder.discounts = result.results || [];
      this.cbOrder.total = result.order.total;
      this.cbOrder.subTotal = result.order.subTotal;
      this.removeRequest();
    }, () => this.removeRequest());
  }

  private loadSplitActiveCart() {
    this.productSplitDefinitionService.getActiveCart({storeId: this.store.id}).subscribe(result => {
      this.productActiveCart = result;
    });
  }

  private openSplitModal(events: ProductSplitDefinitionFullModel[]) {
    const modalSplitInstance = this.modal.open(SplitProductModal, {size: 'lg'});
    modalSplitInstance.componentInstance.splitProductList = events;
  }
}
