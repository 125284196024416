import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { HxStoreProductService, ProductInfoType, StoreProductModel, uiLabel } from 'hx-services';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';

@Component({
  selector: 'app-store-products-search',
  templateUrl: './store-products-search.component.html',
  styleUrls: ['./store-products-search.component.css']
})
export class StoreProductsSearchComponent implements OnInit, OnDestroy {
  @ViewChild('currentItem') currentItem?: ElementRef;
  @Input() onlyView = false;
  @Input() clearOnSelect = false;
  @Input() isPurchasable?: boolean;
  @Input() storeId!: number;
  @Input() productInfoTypes: ProductInfoType[] = [];
  @Output() itemSelect = new EventEmitter<StoreProductModel>();

  isLoading = false;
  items: StoreProductModel[] = [];
  query?: string;
  focused = false;
  selectedItem = false;
  private $typed = new Subject<string>();
  private $destroyed = new Subject<void>();

  constructor(
    private storeProductService: HxStoreProductService,
    private tr: TranslocoService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.$typed
      .pipe(takeUntil(this.$destroyed), debounceTime(400))
      .subscribe(query => this.searchStoreProduct(query));
  }

  ngOnDestroy(): void {
    this.$typed.complete();
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  clear() {
    setTimeout(() => {
      this.focused = false;
    }, 300);
  }

  showOpen() {
    this.focused = true;
  }

  selectItem(item: StoreProductModel) {
    this.query = uiLabel(this.tr.getActiveLang(), item.productInfo.title);
    if (item.unitOfMeasure) {
      this.query += ` (${uiLabel(this.tr.getActiveLang(), item.unitOfMeasure.localShortTitle)})`;
    }
    this.selectedItem = true;
    this.itemSelect.emit(item);
    if (this.clearOnSelect) {
      this.removed();
    }
  }

  typed() {
    this.focused = true;
    if (this.query && this.query.length > 1) {
      this.$typed.next(this.query);
    } else {
      this.items = [];
    }
  }

  removed() {
    this.selectedItem = false;
    this.query = undefined;
  }

  private searchStoreProduct(query: string) {
    this.selectedItem = false;

    if (!query) {
      this.items = [];
      return;
    }

    this.isLoading = true;
    this.storeProductService.getStoreProductList({
      limit: 200,
      query: query,
      purchasable: this.isPurchasable,
      types: this.productInfoTypes,
      storeId: this.storeId
    }).subscribe(result => {
      this.items = result.list;
      this.isLoading = false;
      if (this.items.length === 1) {
        this.selectItem(this.items[0]);
      }
    }, () => this.isLoading = false);
  }
}
