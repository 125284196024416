import { Pipe, PipeTransform } from '@angular/core';
import { toFixed } from '../interface';

@Pipe({
  name: 'vat'
})
export class HxVatPipe implements PipeTransform {

  transform(value: number, percent: number): any {
    return toFixed(value * (percent/100));
  }
}
