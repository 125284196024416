import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { HxOrderService, NotifierEventBusAddress, NotifierService, PaymentType } from 'hx-services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

interface OrderItem {
  id: number;
  phone?: string;
  uniqueNumber: string;
}

@Component({
  selector: 'hc-pay',
  templateUrl: './hc-pay.component.html',
  styleUrls: ['./hc-pay.component.css']
})
export class HcPayComponent implements OnInit, OnDestroy {
  @Input({required: true}) order!: OrderItem;
  @Input({required: true}) paymentType!: PaymentType;
  @Output() orderPaid = new EventEmitter<void>();

  isOrderPaid = false;
  isLoading = {
    sendPayInvoice: false
  };
  clientPhone?: string;
  private ebSub!: Subscription;

  constructor(
    private toastr: ToastrService,
    private domSanitizer: DomSanitizer,
    private errorService: ErrorHandlerService,
    private notifierService: NotifierService,
    private modal: NgbModal,
    private orderService: HxOrderService,
    private tr: TranslocoService
  ) { }

  ngOnInit() {
    this.ebSub = this.notifierService.eventBusOpenObs.subscribe(eb => {
      if (!eb) {
        return;
      }
      this.notifierService.registerHandler(NotifierEventBusAddress.orderEvent(this.order.id), this.streamHandler);
    });
    this.clientPhone = this.order.phone?.substr(1);
  }

  ngOnDestroy() {
    if (this.ebSub) {
      this.ebSub.unsubscribe();
    }
    this.notifierService.unregisterHandler(NotifierEventBusAddress.orderEvent(this.order.id), this.streamHandler);
  }

  checkPaid() {
    this.orderService.checkPaymentStatus({orderId: this.order.id, type: this.paymentType}).subscribe(result => {
      if (result.paid) {
        this.afterOrderPaid();
      } else {
        this.toastr.info('order.notPaid');
      }
    }, err => this.errorService.handle(err));
  }

  async sendPayInvoice() {
    this.isLoading.sendPayInvoice = true;
    const phone = '7' + this.clientPhone;
    try {
      const {total} = await this.orderService.sendPayInvoice(this.order.id, {
        paymentType: PaymentType.KASPI,
        phone: phone,
      });
      this.toastr.success(this.tr.translate('hc-pay.invoiceForPayment', {phone: phone, total: total}));
      if (!this.order.phone) {
        this.orderService.orderUpdated();
      }
    } finally {
      this.isLoading.sendPayInvoice = false;
    }
  }

  private afterOrderPaid() {
    this.toastr.success('order.paid.success');
    this.orderPaid.emit();
  }

  private streamHandler = (error: any, message: any) => {
    if (error) {
      console.error(error);
    } else {
      if (message.headers?.action === 'orderPaid') {
        this.afterOrderPaid();
      }
    }
  };
}
