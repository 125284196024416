import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  AtolDeviceInfo,
  AtolDeviceStatus,
  AtolFiscalResult,
  AtolRequestAddResult,
  AtolRequestResult,
  AuthUserModel,
  filterNullish,
  FiscalInfoModel,
  getPaymentValue,
  HxAuthService,
  HxOrderService,
  HxStoreService,
  PaymentType,
  ReceiptModel,
  toFixed,
  uiLabel
} from 'hx-services';
import { BehaviorSubject, firstValueFrom, from, Observable, of, repeat, throwError, zip } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { CashboxService } from '@cashbox-app/service/cashbox.service';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class FiscalizationService {

  private atolPrefix = 'http://127.0.0.1:16732/api/v2';
  private readonly ATOL_BRAND = 'АТОЛ';
  private isLoaded = new BehaviorSubject<FiscalInfoModel | undefined>(undefined);

  constructor(
    private httpClient: HttpClient,
    private storeService: HxStoreService,
    private orderService: HxOrderService,
    private tr: TranslocoService,
    private auth: HxAuthService,
    private cashboxService: CashboxService,
    private toastr: ToastrService,
  ) {
  }

  getDeviceInfo(): Observable<AtolDeviceInfo> {
    return this.onReady().pipe(mergeMap(fiscalInfo => {
      if (fiscalInfo.deviceBrand === this.ATOL_BRAND) {
        return this.httpClient.post<{ deviceInfo: AtolDeviceInfo }>(`${this.atolPrefix}/operations/queryDeviceInfo`, undefined)
          .pipe(map(data => data.deviceInfo));
      }
      return throwError(() => new Error('unknown device brand'));
    }));
  }

  getDeviceStatus(): Observable<AtolDeviceStatus> {
    return this.onReady().pipe(mergeMap(fiscalInfo => {
      if (fiscalInfo.deviceBrand === this.ATOL_BRAND) {
        return this.httpClient.post<{ deviceStatus: AtolDeviceStatus }>(`${this.atolPrefix}/operations/queryDeviceStatus`, undefined)
          .pipe(map(data => data.deviceStatus));
      }
      return throwError(() => new Error('unimplemented'));
    }));
  }

  getFiscalInfoByStoreId(id: number): Observable<FiscalInfoModel> {
    return this.storeService.getFiscalInfoByStoreId(id).pipe(tap(fiscalInfo => {
      this.isLoaded.next(fiscalInfo);
    }));
  }

  closeShift(): Observable<any> {
    const user: AuthUserModel = this.auth.user;
    const req = {
      'uuid': this.uuidv4(),
      'request': [
        {
          'type': 'closeShift',
          'electronically': !CashboxService.isKktPrint(),
          'operator': {
            'name': user.fullname,
            'vatin': user.iin
          }
        }
      ]
    };
    return this.postRequestAndAwaitResult(req);
  }

  openShift(): Observable<any> {
    const user: AuthUserModel = this.auth.user;
    const req = {
      'uuid': this.uuidv4(),
      'request': [
        {
          'type': 'openShift',
          'electronically': !CashboxService.isKktPrint(),
          'operator': {
            'name': user.fullname,
            'vatin': user.iin
          }
        }
      ]
    };
    return this.postRequestAndAwaitResult(req);
  }

  fiscalizeIfOffline(orderId: number): Promise<boolean> {
    return firstValueFrom(this.onReady().pipe(mergeMap(fiscalInfo => {
      if (!fiscalInfo.online && fiscalInfo.enabled) {
        return this.fiscalize(orderId).pipe(map(() => true));
      } else {
        return of(false);
      }
    })));
  }

  saveAtolFiscalizationData(orderId: number, fiscalResult: AtolFiscalResult): Observable<void> {
    return this.getDeviceInfo().pipe(
      map(deviceInfo => {
        fiscalResult.serialNumber = deviceInfo.serial;
        fiscalResult.type = 'atol';
        return fiscalResult;
      }),
      mergeMap(result => this.orderService.saveFiscalizationData(orderId, result))
    );
  }

  getRequestResult<T>(uuid: string): Observable<T> {
    return this.httpClient.get<AtolRequestResult<T>>(`${this.atolPrefix}/requests/${uuid}`)
      .pipe(filter(res => {
          if (res.results) {
            const result = res.results[0];
            if (result.error.code === 0 && result.status === 'ready') {
              return true;
            }
            if (result.status === 'error') {
              throw new HttpErrorResponse({error: result.error});
            }
          }
          throw new HttpErrorResponse({error: {code: 1000, description: 'atol.task.notReady'}});
        }),
        map(res => res.results[0].result));
  }

  private isOnline(): Observable<boolean> {
    return this.onReady().pipe(map(info => info.online));
  }

  private fiscalize(orderId: number): Observable<void> {
    return zip(this.onReady(), from(this.cashboxService.getFiscalPaperState())).pipe(
      mergeMap(([fiscalInfo, paperState]) => {
        if (fiscalInfo.deviceBrand !== this.ATOL_BRAND) {
          return throwError(() => new Error('unimplemented'));
        }
        if (!paperState.ready) {
          this.toastr.error(this.tr.translate('fiscalization.noFiscalPaper'));
          return throwError(() => new Error('no fiscal paper'));
        }
        return this.getDeviceStatus().pipe(
          mergeMap(deviceStatus => {
            if (CashboxService.isKktPrint() && !deviceStatus.paperPresent) {
              this.toastr.error(this.tr.translate('fiscalization.noFiscalPaper'));
              this.cashboxService.setFiscalPaperState(false)
                .then(() => this.cashboxService.handleFiscalPaperReadyEvent(false));
              return throwError(() => new Error('no fiscal paper'));
            }
            if (deviceStatus.shift !== 'opened') {
              return this.openShift();
            } else {
              return of(undefined);
            }
          }),
          mergeMap(() => this.orderService.getOrderReceipt({orderId: orderId})),
          map(receipt => this.toAtolFiscalizeRequest(receipt, fiscalInfo)),
          mergeMap(fiscalizeReq => this.postRequestAndAwaitResult<{ fiscalParams: AtolFiscalResult }>(fiscalizeReq)),
          map(res => res.fiscalParams),
          mergeMap(fiscalResult => this.saveAtolFiscalizationData(orderId, fiscalResult))
        );
      })
    );
  }

  private uuidv4() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      // eslint-disable-next-line no-bitwise
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  private onReady(): Observable<FiscalInfoModel> {
    return this.isLoaded.pipe(filterNullish(), take(1));
  }

  private postRequest(request: any): Observable<AtolRequestAddResult> {
    return this.httpClient.post<AtolRequestAddResult>(`${this.atolPrefix}/requests`, request);
  }

  private postRequestAndAwaitResult<T>(request: any): Observable<T> {
    return this.httpClient.post<AtolRequestAddResult>(`${this.atolPrefix}/requests`, request).pipe(mergeMap(task => {
      let callCounter = 0;
      return this.httpClient.get<AtolRequestResult<T>>(`${this.atolPrefix}/requests/${task.uuid}`)
        .pipe(
          repeat({delay: 300}),
          filter(res => {
            callCounter += 1;
            if (callCounter === 30) {
              throw new Error('call.limit');
            }
            if (res.results) {
              const result = res.results[0];
              if (result.error.code === 0 && result.status === 'ready') {
                return true;
              }
              if (result.status === 'error') {
                //44 = Нет бумаги
                if (result.error.code === 44) {
                  this.cashboxService.setFiscalPaperState(false)
                    .then(() => this.cashboxService.handleFiscalPaperReadyEvent(false));
                }
                throw new Error(JSON.stringify(result.error));
              }
            }
            return false;
          }),
          map(res => res.results[0].result),
          take(1)
        );
    }));
  }

  private toAtolFiscalizeRequest(receipt: ReceiptModel, fiscalInfo: FiscalInfoModel): any {
    const user = this.auth.user;

    const coinAmount =  getPaymentValue(receipt.payments, PaymentType.COIN);
    const fixedAmount = 10;
    let leftCoinAmount = coinAmount;
    const items = receipt.products.map(product => {
      const productDiscounts = receipt.discounts.filter(dsc => dsc.enabled && !dsc.removed && dsc.productId === product.id);
      const discount = toFixed(productDiscounts.reduce((current, dsc) => current + dsc.value, 0));
      let amount = toFixed(Math.abs(product.value) - discount);
      const quantity = Math.abs(product.amount);

      let finalAmount: number;
      if (leftCoinAmount > 0) {
        if (leftCoinAmount > amount) {
          finalAmount = fixedAmount;
          leftCoinAmount -= amount - finalAmount;
        } else {
          finalAmount = amount - leftCoinAmount;
          leftCoinAmount = 0;
        }
      } else {
        finalAmount = amount;
      }

      return {
        type: 'position',
        name: uiLabel(this.tr.getActiveLang(), product.title),
        price: toFixed(finalAmount/quantity),
        quantity: quantity,
        amount: finalAmount,
        infoDiscountAmount: discount,
        tax: {type: fiscalInfo.taxType},
      };
    });
    /*
    let totalDiscount = Math.abs(receipt.subTotal) - Math.abs(receipt.total);
    const minimumPrice = 20;
    if (totalDiscount > 0) {
      items.forEach(item => {
        let discount;
        let priceValueWithDiscount = item.amount;
        if (priceValueWithDiscount <= totalDiscount) {
          totalDiscount = totalDiscount - priceValueWithDiscount + minimumPrice;
          priceValueWithDiscount = minimumPrice;

          discount = item.amount - minimumPrice;
        } else {
          priceValueWithDiscount -= totalDiscount;

          discount = totalDiscount;
          totalDiscount = 0.0;
        }

        // decimal point ge 3 (0.125 is NOT ok, 0.05 is OK)
        if ((toFixed(priceValueWithDiscount / item.quantity, 5) % 1 + '').length > 4) {

        }

        item.amount = toFixed(priceValueWithDiscount);
        item.infoDiscountAmount = toFixed(discount);
        item.price = toFixed(priceValueWithDiscount / item.quantity, 2);
      });
    }*/
    const cash = Math.abs(getPaymentValue(receipt.payments, PaymentType.CASH));
    const card = Math.abs(receipt.total) - Math.abs(cash) - coinAmount;
    const orderTotal = Math.abs(receipt.total) - coinAmount;
    const payments = [];
    if (cash) {
      payments.push({
        type: 'cash',
        sum: toFixed(cash)
      });
    }
    if (card) {
      payments.push({
        type: 'electronically',
        sum: toFixed(card)
      });
    }
    const type = receipt.isRefund ? 'sellReturn' : 'sell';
    return {
      uuid: receipt.eventUuid,
      request: [
        {
          type: type,
          electronically: !CashboxService.isKktPrint(),
          taxationType: fiscalInfo.taxationType,
          ignoreNonFiscalPrintErrors: false,
          operator: {
            name: user.fullname,
            vatin: user.iin
          },
          items: items,
          payments: payments,
          total: orderTotal,
        }
      ]
    };
  }
}
