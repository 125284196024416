<div class="input-group" [ngClass]="{'error': hasError}">
  <input #viewInput class="form-control" [disabled]="isDisabled" [(ngModel)]="viewModel" (ngModelChange)="onViewModelChanged()" [placeholder]="placeholder">
  <input style="display: none;"
         ngbDatepicker
         (dateSelect)="onDateSelected($event)"
         [displayMonths]="2"
         [dayTemplate]="dateRangeTemplate"
         [footerTemplate]="footerTemplate"
         [startDate]="startDate"
         [minDate]="minDate"
         [maxDate]="maxDate"
         [positionTarget]="viewInput"
         [markDisabled]="markDisabled"
         outsideDays="hidden"
         autoClose="outside"
         #dateRangePicker="ngbDatepicker">
  <div class="input-group-append">
    <button class="btn btn-outline-secondary" (click)="dateRangePicker.toggle()" type="button">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4 fill-current" style="height: 12px; width: 12px;">
        <path d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"></path>
      </svg>
    </button>
  </div>
</div>

<ng-template #dateRangeTemplate let-date let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = undefined">
    {{ date.day }}
  </span>
</ng-template>

<ng-template #footerTemplate>
  <div class="buttons">
    <div class="btn-group btn-group-sm">
      <button class="btn btn-primary btn-sm" (click)="setLastWeek()">{{ 'hx.date-range-select.lastWeek' | transloco }}</button>
      <button class="btn btn-primary btn-sm" (click)="setLastMonth()">{{ 'hx.date-range-select.lastMonth' | transloco }}</button>
      <button class="btn btn-primary btn-sm" (click)="setLastQuarter()">{{ 'hx.date-range-select.lastQuarter' | transloco }}</button>
    </div>
    <div class="btn-group btn-group-sm m--margin-top-15">
      <button class="btn btn-primary btn-sm" (click)="setThisWeek()">{{ 'hx.date-range-select.week' | transloco }}</button>
      <button class="btn btn-primary btn-sm" (click)="setThisMonth()">{{ 'hx.date-range-select.month' | transloco }}</button>
      <button class="btn btn-primary btn-sm" (click)="setThisQuarter()">{{ 'hx.date-range-select.quarter' | transloco }}</button>
    </div>

    <button class="btn btn-success btn-sm m--margin-top-15 pull-right" (click)="apply()">{{ 'hx.btn.apply' | transloco }}</button>
  </div>
</ng-template>
