import { Inject, Injectable, Optional } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { format, parse } from 'date-fns';
import { HX_DATE_FORMAT } from './datepicker';
import { TranslationWidth } from '@angular/common';

@Injectable()
export class HxDatepickerLocale extends NgbDatepickerI18n {
  private I18N_VALUES = {
    ru: {
      weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    },
    kk: {
      weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    },
    en: {
      weekdays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
    // other languages you would support
  };

  public static currentLang: string = 'ru';

  getMonthShortName(month: number): string {
    return this.I18N_VALUES[HxDatepickerLocale.currentLang as keyof typeof this.I18N_VALUES].months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.I18N_VALUES[HxDatepickerLocale.currentLang as keyof typeof this.I18N_VALUES].weekdays[weekday - 1];
  }
}

@Injectable()
export class HxDateParserFormatter extends NgbDateParserFormatter {

  constructor(@Optional() @Inject(HX_DATE_FORMAT) private dateFormat: string) {
    super();
    if (!this.dateFormat) {
      this.dateFormat = 'dd.MM.yyyy';
    }
    // console.log('[HxDateParserFormatter] using date format:', this.dateFormat);
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    return format(new Date(date.year, date.month - 1, date.day), this.dateFormat);
  }

  parse(value?: string): NgbDateStruct | null {
    if (!value) {
      return null;
    }
    const parsed = parse(value, this.dateFormat, new Date(1900, 0, 1));
    if (parsed && parsed.getFullYear() !== 1900 && parsed.getFullYear() > 1900) {
      return new NgbDate(parsed.getFullYear(), parsed.getMonth() + 1, parsed.getDate());
    }
    return null;
  }
}

