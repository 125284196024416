import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, ErrorHandler, Injectable, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from '@cashbox-app/app.component';
import { AppRoutingModule } from '@cashbox-app/app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HeaderComponent } from '@cashbox-app/components/header/header.component';
import { FooterComponent } from '@cashbox-app/components/footer/footer.component';
import { SidebarComponent } from '@cashbox-app/components/sidebar/sidebar.component';

import { HomeComponent } from '@cashbox-app/pages/home/home.component';
import { InvoiceListComponent } from '@cashbox-app/pages/invoice-list/invoice-list.component';
import { MoneyReportComponent } from '@cashbox-app/pages/cash/money-report/money-report.component';
import { ProductKindListComponent } from '@cashbox-app/components/product-kind-list/product-kind-list.component';
import { CustomHttpInterceptor } from '@cashbox-app/service/http.interceptor';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { CancelReasonComponent } from '@cashbox-app/components/cancel-reason/cancel-reason.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import * as Sentry from '@sentry/browser';
import { environment } from '@cashbox-env/environment';
import { HcAlertComponent } from '@cashbox-app/components/hc-alert/hc-alert.component';
import { HcNotifierComponent } from '@cashbox-app/components/hc-notifier/hc-notifier.component';
import { SettingsComponent } from '@cashbox-app/pages/settings/settings.component';
import { HcFiscalNumberSearchComponent } from '@cashbox-app/components/hc-fiscal-number-search/hc-fiscal-number-search.component';
import { OrderViewComponent } from '@cashbox-app/pages/order-view/order-view.component';
import { OrderDeliveryComponent } from '@cashbox-app/pages/order-delivery/order-delivery.component';
import { OrderCashboxComponent } from '@cashbox-app/pages/order-cashbox/order-cashbox.component';
import { OrderCancelModal } from '@cashbox-app/modal/order-cancel/order-cancel.modal';
import { OrderProductCancelModal } from '@cashbox-app/modal/order-product-cancel/order-product-cancel.modal';
import { UserOrderComponent } from '@cashbox-app/pages/user-order/user-order.component';
import { HcOtpComponent } from '@cashbox-app/components/hc-otp/hc-otp.component';
import { CartListComponent } from '@cashbox-app/components/cart-list/cart-list.component';
import { HcIconComponent } from '@cashbox-app/components/hc-icon/hc-icon.component';
import { HcPayComponent } from '@cashbox-app/components/hc-pay/hc-pay.component';
import { HxComponentModule } from 'hx-component';
import {
  ApiModule,
  ComponentType,
  Configuration,
  flattenObject,
  HX_APP_VERSION,
  HX_COMPONENT_NAME,
  HX_ENV_MODE,
  HX_KEYCLOAK,
  HxAuthService,
  HxError,
  HxPipeModule,
  initKeycloak,
  TranslocoRootModule,
  UiError
} from 'hx-services';
import { BalanceNewModal } from '@cashbox-app/modal/balance-new/balance-new.modal';
import { StoreProductsSearchComponent } from '@cashbox-app/components/store-products-search/store-products-search.component';
import { HxOrderModule } from 'hx-order';
import { BalanceViewComponent } from '@cashbox-app/components/balance-view/balance-view.component';
import { InvoiceEventsComponent } from '@cashbox-app/components/invoice-events/invoice-events.component';
import { InvoiceModal } from '@cashbox-app/modal/invoice/invoice.modal';
import { StoreProductComponent } from '@cashbox-app/components/store-product/store-product.component';
import { InvoiceProductComponent } from '@cashbox-app/components/invoice-product/invoice-product.component';
import { FiscalizationComponent } from '@cashbox-app/pages/fiscalization/fiscalization.component';
import { PaymentInfoPipe } from '@cashbox-app/service/payment-info.pipe';
import { ProductInfoPipe } from '@cashbox-app/service/product-info.pipe';
import { CashboxOperationModal } from '@cashbox-app/modal/cashbox-operation/cashbox-operation.modal';
import { OrderInvoiceConfirmationModal } from '@cashbox-app/modal/order-invoice-confirmation/order-invoice-confirmation.modal';
import { SplitProductModal } from '@cashbox-app/modal/split-product/split-product.modal';
import { APP_VERSION } from '@cashbox-env/version';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { CurbsidePickupComponent } from '@cashbox-app/components/curbside-pickup/curbside-pickup.component';
import { SinceComponent } from '@cashbox-app/components/since/since.component';
import { TranslocoService } from '@ngneat/transloco';

if (environment.sentryUrl) {
  Sentry.init({
    release: environment.version,
    environment: environment.production ? 'production' : 'staging',
    ignoreErrors: ['Unauthorized', 'productBalance.zero'],
    dsn: environment.sentryUrl,
    sampleRate: 0,
    autoSessionTracking: false,
    tracePropagationTargets: [],
    beforeSend: (event, hint) => {
      console.log('[sentry] before send', event, hint);
      if (hint && hint.originalException && hint.originalException instanceof HttpErrorResponse) {
        if (hint.originalException.status === 401) {
          console.log('[sentry] skipped error logging', event);
          return null;
        }
      }
      return event;
    },
    integrations: [
      new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false,
      }),
    ],
  });
}

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error: any) {
    if (error.promise && error.rejection) {
      console.error('[got-rejection] %O', error);
      error = error.rejection;
    }
    const toastr = this.injector.get(ToastrService);
    const tr = this.injector.get(TranslocoService);
    if (error instanceof HttpErrorResponse) {
      const ex: HxError = error.error?.message ? error.error as HxError : {
        code: 577,
        id: '-',
        message: error.message,
        data: error.error ?? {},
      };
      toastr.error(ex.description ?? tr.translate(ex.message, flattenObject(ex.data)), tr.translate('error') + ' ' + ex.id);
    }
    if (error instanceof UiError) {
      toastr.error(tr.translate(error.message, error.data ?? {}));
    } else {
      console.error('[error-handler] %O', error);
    }
    Sentry.captureException(error.originalError || error);
  }
}

function getKeycloakUrl() {
  if (environment.production) {
    return location.origin.replace(location.origin.split('//')[1].split('.')[0], 'auth') + '/auth';
  }
  return environment.keycloakUrl;
}

@NgModule({
  declarations: [
    AppComponent,
    CurbsidePickupComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    HomeComponent,
    OrderViewComponent,
    OrderDeliveryComponent,
    OrderCashboxComponent,
    InvoiceListComponent,
    OrderCancelModal,
    OrderProductCancelModal,
    MoneyReportComponent,
    UserOrderComponent,
    StoreProductComponent,
    InvoiceEventsComponent,
    ProductKindListComponent,
    CancelReasonComponent,
    HcAlertComponent,
    HcNotifierComponent,
    SettingsComponent,
    HcFiscalNumberSearchComponent,
    HcOtpComponent,
    CartListComponent,
    BalanceViewComponent,
    InvoiceProductComponent,
    HcIconComponent,
    HcPayComponent,
    BalanceNewModal,
    StoreProductsSearchComponent,
    InvoiceModal,
    FiscalizationComponent,
    SinceComponent,
    CashboxOperationModal,
    OrderInvoiceConfirmationModal,
    PaymentInfoPipe,
    ProductInfoPipe,
    SplitProductModal,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    HxComponentModule,
    HxOrderModule,
    ToastrModule.forRoot(),
    TranslocoRootModule,
    NgSelectModule,
    KeycloakAngularModule,
    HxPipeModule,
    HxComponentModule,
    NgxMaskPipe,
    NgxMaskDirective,
    ApiModule.forRoot(() => new Configuration({basePath: ''})),
  ],
  providers: [
    provideEnvironmentNgxMask(),
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: HX_COMPONENT_NAME,
      useValue: ComponentType.cb
    },
    {
      provide: HX_APP_VERSION,
      useValue: APP_VERSION
    },
    {
      provide: HX_KEYCLOAK,
      useValue: {
        url: getKeycloakUrl(),
        clientId: environment.keycloakClientId,
        realm: environment.keycloakRealm,
        excludeUrls: ['/api/v2'],
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService, HxAuthService, HX_KEYCLOAK, TranslocoService],
    },
    {provide: HX_ENV_MODE, useValue: environment.production},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
